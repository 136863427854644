var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"calendarMatch",staticClass:"calendar-match"},[_c('div',{staticClass:"date",style:(_vm.dateStyles)},[_c('span',{staticClass:"date__dayofweek"},[_vm._v(_vm._s(_vm.formatedDate.dayOfWeek))]),_c('span',{staticClass:"date__day"},[_vm._v(_vm._s(_vm.date.getUTCDate()))]),_c('span',{staticClass:"date__month"},[_vm._v(_vm._s(_vm.formatedDate.month))])]),_c('div',{staticClass:"game",class:`game--${_vm.statusOptions[_vm.isLive ? 1 : 2]}`,style:(_vm.landingStyles.elementHeight)},[_c('div',{staticClass:"game__container"},[(_vm.isLive)?_c('div',{staticClass:"game__live-tag"},[_c('span',[_vm._v("• ")]),_c('span',[_vm._v("LIVE")])]):_c('span',{staticClass:"game__time"},[_vm._v(_vm._s(_vm.time)+" HRS")]),_c('div',{class:{
            game__teams: _vm.getPremiumAccount,
            'game__teams--live': !_vm.getPremiumAccount,
            top: !_vm.getPremiumAccount || !_vm.probabilities || !_vm.predictions,
          },style:({
            'margin-top': _vm.isPostMatch || _vm.isLive || !_vm.probabilities ? '0.8rem' : '',
            'margin-top': _vm.isLanding ? '0' : '',
          })},[_c('div',{staticClass:"game__teams-left"},[(!_vm.isPostMatch)?_c('img',{class:{
                'game__teams-logo': _vm.getPremiumAccount && _vm.predictions,
                'game__teams-logo2': !_vm.getPremiumAccount || !_vm.predictions,
              },attrs:{"src":'https://az755631.vo.msecnd.net/teams-80/' + _vm.teamLeft.team_id + '.png',"alt":"logo del equipo 1"}}):(_vm.isPostMatch)?_c('div',{staticClass:"game__teams-logo--live"},[_c('img',{staticClass:"game__teams-logo2",attrs:{"src":'https://az755631.vo.msecnd.net/teams-80/' + _vm.teamLeft.team_id + '.png',"alt":"logo del equipo 1"}}),_c('div',{staticClass:"game__score"},[_vm._v(_vm._s(_vm.score[0]))])]):_c('div',{staticClass:"game__teams-logo--live"},[_c('img',{staticClass:"game__teams-logo",attrs:{"src":'https://az755631.vo.msecnd.net/teams-80/' + _vm.teamLeft.team_id + '.png',"alt":"logo del equipo 1"}}),_c('div',{staticClass:"game__score"},[_vm._v(_vm._s(_vm.score[0]))])]),_c('span',{class:{
                'game__teams-acronym4': _vm.gridNum == 4,
                'game__teams-acronym3': _vm.gridNum == 3,
                'game__teams-acronym2': _vm.gridNum == 2,
                'game__teams-acronym1': _vm.gridNum == 1,
              }},[_vm._v(_vm._s(_vm.teamLeft.acronym))])]),_c('div',{class:{
              'game__teams-vsg1': _vm.gridNum == 1,
              'game__teams-vsg2': _vm.gridNum == 2,
              'game__teams-vsg3': _vm.gridNum == 3,
              'game__teams-vsg4': _vm.gridNum == 4,
            }},[_vm._v(" VS ")]),_c('div',{staticClass:"game__teams-right"},[_c('span',{class:{
                'game__teams-acronym4': _vm.gridNum == 4,
                'game__teams-acronym3': _vm.gridNum == 3,
                'game__teams-acronym2': _vm.gridNum == 2,
                'game__teams-acronym1': _vm.gridNum == 1,
              }},[_vm._v(_vm._s(_vm.teamRight.acronym))]),(!_vm.isPostMatch)?_c('img',{class:{
                'game__teams-logo': _vm.getPremiumAccount && _vm.predictions,
                'game__teams-logo2': !_vm.getPremiumAccount || !_vm.predictions,
              },attrs:{"src":'https://az755631.vo.msecnd.net/teams-80/' + _vm.teamRight.team_id + '.png',"alt":"logo del equipo 1"}}):(_vm.isPostMatch)?_c('div',{staticClass:"game__teams-logo--live"},[_c('img',{staticClass:"game__teams-logo2",attrs:{"src":'https://az755631.vo.msecnd.net/teams-80/' + _vm.teamRight.team_id + '.png',"alt":"logo del equipo 2"}}),_c('div',{staticClass:"game__score"},[_vm._v(_vm._s(_vm.score[1]))])]):_c('div',{staticClass:"game__teams-logo--live"},[_c('img',{staticClass:"game__teams-logo",attrs:{"src":'https://az755631.vo.msecnd.net/teams-80/' + _vm.teamRight.team_id + '.png',"alt":"logo del equipo 2"}}),_c('div',{staticClass:"game__score"},[_vm._v(_vm._s(_vm.score[1]))])])])]),_c('div',{staticClass:"game__stats"},[(!_vm.isPostMatch)?[(_vm.getPremiumAccount && _vm.probabilities)?[(!_vm.isLive)?_c('StatsContainer',{attrs:{"titles":_vm.probsTitles,"contents":_vm.probsContents,"color":_vm.color,"background":_vm.background}}):_vm._e()]:_vm._e(),_c('div',{staticClass:"game__stats-xg",style:(_vm.isLive || !_vm.probabilities ? 'margin-top: 0.8rem' : '')},[(_vm.getPremiumAccount && _vm.predictions)?[_c('StatsContainer',{attrs:{"titles":_vm.xgTitles,"contents":_vm.leftXgConent,"color":_vm.color,"background":_vm.background}}),_c('StatsContainer',{attrs:{"titles":_vm.xgTitlesReversed,"contents":_vm.rightXgConent,"color":_vm.color,"background":_vm.background}})]:_vm._e()],2)]:_vm._e()],2)]),_c('div',{staticClass:"actions",on:{"click":_vm.onClickViewStats}},[_c('div',{class:_vm.gridNum == 4
              ? 'actions__info-button-4'
              : _vm.gridNum == 3
              ? 'actions__info-button-3'
              : _vm.gridNum == 2
              ? 'actions__info-button-2'
              : _vm.gridNum == 1
              ? 'actions__info-button-1'
              : 'actions__info-button',attrs:{"role":"button"},on:{"click":function($event){return _vm.$emit('action')}}},[_c('span',{class:_vm.gridNum == 4
                ? 'actions__info-button-4__text-mobile'
                : _vm.gridNum == 3
                ? 'actions__info-button-3__text-mobile'
                : _vm.gridNum == 2
                ? 'actions__info-button-2__text-mobile'
                : _vm.gridNum == 1
                ? 'actions__info-button-1__text-mobile'
                : 'actions__info-button__text-mobile'},[_vm._v("Ver")]),_c('span',{class:_vm.gridNum == 4
                ? 'actions__info-button-4__text-stats'
                : _vm.gridNum == 3
                ? 'actions__info-button-3__text-stats'
                : _vm.gridNum == 2
                ? 'actions__info-button-2__text-stats'
                : _vm.gridNum == 1
                ? 'actions__info-button-1__text-stats'
                : 'actions__info-button__text-stats'},[(_vm.gridNum == 4 || _vm.gridNum == 3)?[_vm._v("Ver")]:[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }