<template>
  <div class="calendar-match" ref="calendarMatch">
    <div class="date" :style="dateStyles">
      <span class="date__dayofweek">{{ formatedDate.dayOfWeek }}</span>
      <span class="date__day">{{ date.getUTCDate() }}</span>
      <span class="date__month">{{ formatedDate.month }}</span>
    </div>
    <div class="game" :class="`game--${statusOptions[isLive ? 1 : 2]}`" :style="landingStyles.elementHeight">
      <div class="game__container">
        <div v-if="isLive" class="game__live-tag">
          <span>• </span>
          <span>LIVE</span>
        </div>
        <span v-else class="game__time">{{ time }} HRS</span>
        <div
          :class="{
            game__teams: getPremiumAccount,
            'game__teams--live': !getPremiumAccount,
            top: !getPremiumAccount || !probabilities || !predictions,
          }"
          :style="{
            'margin-top': isPostMatch || isLive || !probabilities ? '0.8rem' : '',
            'margin-top': isLanding ? '0' : '',
          }"
        >
          <div class="game__teams-left">
            <img
              v-if="!isPostMatch"
              :class="{
                'game__teams-logo': getPremiumAccount && predictions,
                'game__teams-logo2': !getPremiumAccount || !predictions,
              }"
              :src="'https://az755631.vo.msecnd.net/teams-80/' + teamLeft.team_id + '.png'"
              alt="logo del equipo 1"
            />
            <div v-else-if="isPostMatch" class="game__teams-logo--live">
              <img
                class="game__teams-logo2"
                :src="'https://az755631.vo.msecnd.net/teams-80/' + teamLeft.team_id + '.png'"
                alt="logo del equipo 1"
              />
              <div class="game__score">{{ score[0] }}</div>
            </div>
            <div v-else class="game__teams-logo--live">
              <img
                class="game__teams-logo"
                :src="'https://az755631.vo.msecnd.net/teams-80/' + teamLeft.team_id + '.png'"
                alt="logo del equipo 1"
              />
              <div class="game__score">{{ score[0] }}</div>
            </div>
            <span
              :class="{
                'game__teams-acronym4': gridNum == 4,
                'game__teams-acronym3': gridNum == 3,
                'game__teams-acronym2': gridNum == 2,
                'game__teams-acronym1': gridNum == 1,
              }"
              >{{ teamLeft.acronym }}</span
            >
          </div>
          <div
            :class="{
              'game__teams-vsg1': gridNum == 1,
              'game__teams-vsg2': gridNum == 2,
              'game__teams-vsg3': gridNum == 3,
              'game__teams-vsg4': gridNum == 4,
            }"
          >
            VS
          </div>
          <div class="game__teams-right">
            <span
              :class="{
                'game__teams-acronym4': gridNum == 4,
                'game__teams-acronym3': gridNum == 3,
                'game__teams-acronym2': gridNum == 2,
                'game__teams-acronym1': gridNum == 1,
              }"
              >{{ teamRight.acronym }}</span
            >
            <img
              v-if="!isPostMatch"
              :class="{
                'game__teams-logo': getPremiumAccount && predictions,
                'game__teams-logo2': !getPremiumAccount || !predictions,
              }"
              :src="'https://az755631.vo.msecnd.net/teams-80/' + teamRight.team_id + '.png'"
              alt="logo del equipo 1"
            />
            <div v-else-if="isPostMatch" class="game__teams-logo--live">
              <img
                class="game__teams-logo2"
                :src="'https://az755631.vo.msecnd.net/teams-80/' + teamRight.team_id + '.png'"
                alt="logo del equipo 2"
              />
              <div class="game__score">{{ score[1] }}</div>
            </div>
            <div v-else class="game__teams-logo--live">
              <img
                class="game__teams-logo"
                :src="'https://az755631.vo.msecnd.net/teams-80/' + teamRight.team_id + '.png'"
                alt="logo del equipo 2"
              />
              <div class="game__score">{{ score[1] }}</div>
            </div>
          </div>
        </div>
        <div class="game__stats">
          <template v-if="!isPostMatch">
            <template v-if="getPremiumAccount && probabilities">
              <StatsContainer
                v-if="!isLive"
                :titles="probsTitles"
                :contents="probsContents"
                :color="color"
                :background="background"
              />
            </template>
            <div class="game__stats-xg " :style="isLive || !probabilities ? 'margin-top: 0.8rem' : ''">
              <template v-if="getPremiumAccount && predictions">
                <StatsContainer :titles="xgTitles" :contents="leftXgConent" :color="color" :background="background" />
                <StatsContainer
                  :titles="xgTitlesReversed"
                  :contents="rightXgConent"
                  :color="color"
                  :background="background"
                />
              </template>
            </div>
          </template>
        </div>
      </div>
      <div class="actions" @click="onClickViewStats">
        <div
          role="button"
          :class="
            gridNum == 4
              ? 'actions__info-button-4'
              : gridNum == 3
              ? 'actions__info-button-3'
              : gridNum == 2
              ? 'actions__info-button-2'
              : gridNum == 1
              ? 'actions__info-button-1'
              : 'actions__info-button'
          "
          @click="$emit('action')"
        >
          <span
            :class="
              gridNum == 4
                ? 'actions__info-button-4__text-mobile'
                : gridNum == 3
                ? 'actions__info-button-3__text-mobile'
                : gridNum == 2
                ? 'actions__info-button-2__text-mobile'
                : gridNum == 1
                ? 'actions__info-button-1__text-mobile'
                : 'actions__info-button__text-mobile'
            "
            >Ver</span
          >
          <span
            :class="
              gridNum == 4
                ? 'actions__info-button-4__text-stats'
                : gridNum == 3
                ? 'actions__info-button-3__text-stats'
                : gridNum == 2
                ? 'actions__info-button-2__text-stats'
                : gridNum == 1
                ? 'actions__info-button-1__text-stats'
                : 'actions__info-button__text-stats'
            "
          >
            <template v-if="gridNum == 4 || gridNum == 3">Ver</template>
            <template v-else>
              {{ buttonText }}
            </template>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
export default {
  name: 'CalendarMatchLanding',
  components: { StatsContainer: () => import('@/components/Sections/Calendar/StatsContainerLanding') },
  props: {
    isPremiumUser: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#fff',
    },
    predictions: {
      type: Boolean,
      default: true,
    },
    background: {
      type: String,
      default: '#DBE3E6',
    },
    backgroundLive: {
      type: String,
      default: '#77a405',
    },
    gridNum: {
      type: Number,
      required: true,
    },
    isGameLive: {
      type: Boolean,
      default: false,
    },
    time: {
      type: String,
      default: '20:30',
    },
    date: {
      type: Date,
      default: () => new Date(),
    },
    teamRight: {
      type: Object,
      required: true,
    },
    teamLeft: {
      type: Object,
      required: true,
    },
    gameStatus: {
      type: Number,
      required: true,
    },
    score: {
      type: Array,
      default: () => [],
    },
    percentages: {
      type: Array,
      default: () => [],
    },
    homeGoals: {
      type: Array,
      default: () => [],
    },
    visitingGoals: {
      type: Array,
      default: () => [],
    },
    probabilities: {
      type: [Object, null],
      default: () => null,
    },
    buttonText: {
      type: String,
      default: 'Estadísticas',
    },
    isLanding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statusOptions: {
        0: 'pending',
        1: 'live',
        2: 'finished',
      },
      probsTitles: ['GANA', 'EMPATE', 'GANA'],
      xgTitles: ['Goles', 'xG'],
    };
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount']),
    xgTitlesReversed() {
      return this.xgTitles.map(i => i).reverse();
    },
    leftXgConent() {
      return this.homeGoals;
    },
    rightXgConent() {
      return this.visitingGoals.map(i => i).reverse();
    },
    dateStyles() {
      return {
        color: this.color,
        background: this.isLive ? '#77a405' : '#132839',
        height: this.isLanding ? '110px' : '113px',
      };
    },
    landingStyles() {
      return {
        elementHeight: {
          height: this.isLanding ? '109px' : '113px',
        },
      };
    },
    buttonStyles() {
      return {
        color: this.color,
        background: this.background,
      };
    },
    formatedDate() {
      const dayOfWeek = this.date
        .toLocaleDateString('es-ES', { weekday: 'long', timeZone: 'UTC' })
        .split(', ')[0]
        .substring(0, 3);

      const month = new Intl.DateTimeFormat('es-ES', { month: 'short', timeZone: 'UTC' })
        .format(this.date)
        .split('.')[0];

      return { month, dayOfWeek };
    },
    probsContents() {
      return this.percentages.map(p => `${p}%`);
    },
    isLive() {
      return this.gameStatus === 4 && this.isGameLive;
    },
    isPostMatch() {
      return this.gameStatus === 1 || this.gameStatus === 0;
    },
  },
  methods: {
    ...mapMutations('general', ['setPreviewSectionAnalytics']),
    onClickViewStats() {
      this.setPreviewSectionAnalytics('Calendar');
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  margin-top: 16px;
  @media screen and (max-width: 350px) {
    margin-top: 24px;
  }
}
.calendar-match {
  width: 100%;
  box-sizing: content-box;
  display: flex;
  font-family: 'Avenir-Regular';
  & .date,
  & .game {
    height: 113px;
    border-radius: 4px;
  }

  & .date {
    text-align: center;
    width: 15%;
    min-width: 44px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & * {
      cursor: default;
    }

    & > span {
      letter-spacing: -0.01rem;
      line-height: 1rem;
    }

    &__dayofweek,
    &__month {
      text-transform: uppercase;
      font-family: 'Avenir-Demi';
      font-size: 0.545rem;
    }

    &__day {
      font-family: 'Circular-Std';
      font-size: 1.625rem;
    }
  }

  & .game {
    width: 82%;
    display: flex;
    border: solid 0.8px #d2d2d2;
    justify-content: center;

    &--finished {
      width: 82%;
    }

    &__score {
      font-family: 'Circular-Std-Bold';
      font-size: 1.5rem;
      color: #132839 !important;
    }

    &__container {
      width: 72.5%;
      margin: 0 0.7rem;
      & * {
        cursor: default;
      }
    }

    &__live-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-top: 2px;
      width: 2rem;
      border-radius: 3px;
      font-size: 0.45rem;
      line-height: 1.75em;
      font-weight: normal;
      font-family: 'Avenir-Pro-Medium';
      background: #3c3c3c;

      & span:first-child {
        color: #77a405;
        font-size: 1.5em;
        margin-right: 3px;
      }

      & span:last-child {
        color: #fff;
      }
    }

    &__time {
      display: block;
      font-size: 0.6rem;
      font-weight: 600;
      font-family: 'Avenir-Pro-Medium';
      margin-top: 1px;
      color: #132839;
    }
    &__teams,
    &__teams--live {
      display: flex;
      justify-content: center;
      align-items: center;
      &-left,
      &-vs,
      &-vs2,
      &-vsg1,
      &-vsg2,
      &-vsg3,
      &-vsg4,
      &-right {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #132839;
      }
      &-vs2 {
        font-size: 0.8rem;
        font-family: 'Circular-Std-Book';
        margin: 0 1rem;
        color: #132839;
        @media screen and (max-width: 500px) {
          margin: 0 0.6rem;
        }
        @media screen and (max-width: 320px) {
          margin: 0 0.3rem;
        }
      }
      &-vsg1 {
        font-size: 0.8rem;
        font-family: 'Circular-Std-Book';
        margin: 0 1rem;
        color: #132839;
        @media screen and (max-width: 500px) {
          margin: 0 0.6rem;
        }
        @media screen and (max-width: 320px) {
          margin: 0 0.3rem;
        }
      }
      &-vsg2 {
        font-size: 0.8rem;
        font-family: 'Circular-Std-Book';
        margin: 0 0.4rem;
        color: #132839;
      }
      &-vsg3 {
        font-size: 0.8rem;
        font-family: 'Circular-Std-Book';
        margin: 0 0.4rem;
        color: #132839;
      }
      &-vsg4 {
        font-size: 0.8rem;
        font-family: 'Circular-Std-Book';
        margin: 0 1rem;
        color: #132839;
        @media screen and (max-width: 500px) {
          margin: 0 0.6rem;
        }
        @media screen and (max-width: 320px) {
          margin: 0 0.3rem;
        }
      }
      &-left,
      &-right {
        font-family: 'Circular-Std-Bold';
        font-size: 0.8125rem;
      }
      &-vs {
        font-family: 'Circular-Std-Book';
        margin: 0 1rem;
        font-size: 0.6875rem;
        @media screen and (max-width: 500px) {
          margin: 0 0.6rem;
        }
        @media screen and (max-width: 320px) {
          margin: 0 0.3rem;
        }
      }
      &-logo {
        height: 30px;
        // margin: auto 5px;
        &--live {
          height: 30px;
        }
      }
      &-logo2 {
        height: 45px;
        // margin: auto 5px;
        font-size: 1.2rem;
        @media screen and (max-width: 390px) {
          height: 38px;
        }
        &--live {
          height: 30px;
        }
      }
      &-acronym2 {
        font-size: 1rem;
        padding-left: 5px;
        padding-right: 5px;
        @media screen and (max-width: 350px) {
          font-size: 1rem;
        }
        visibility: hidden;
      }
      &-acronym4 {
        font-size: 0.4rem;
        visibility: hidden;
      }
      &-acronym3 {
        font-size: 0.9rem;
        visibility: hidden;
      }
      &-acronym1 {
        font-size: 0.9rem;
        visibility: hidden;
      }
    }

    &__teams--live {
      height: max-content;
      .game__teams-logo {
        height: 45px;
        margin: auto;
        &--live {
          height: 45px;
          min-width: 45px;
        }
      }
    }

    &__stats {
      margin: auto;
      &-xg {
        display: flex;
        justify-content: space-between;
        width: 100%;
        & > div {
          width: 48%;
        }
      }
    }
  }

  & .actions {
    height: 100%;
    display: flex;
    align-items: center;

    &__info-button {
      box-sizing: border-box;
      font-family: 'Circular-Std-Medium';
      font-size: 10px;
      color: #3a3a3a;
      letter-spacing: -0.05px;
      text-align: center;
      margin-top: 0.5rem;
      background: rgb(254, 208, 37);
      background: linear-gradient(180deg, rgba(254, 208, 37, 1) 0%, rgba(240, 178, 17, 1) 100%);
      width: 4.1rem;
      height: 1.9rem;
      line-height: 20px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
      border-radius: 8px;
      padding: 0.4rem;
      border-radius: 7px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
      cursor: pointer;
      &.live {
        background-color: #77a405;
        color: #fff;
      }
    }
    &__info-button-1 {
      box-sizing: border-box;
      font-family: 'Circular-Std-Medium';
      font-size: 12px;
      color: #3a3a3a;
      letter-spacing: -0.05px;
      text-align: center;
      margin-top: 0.5rem;
      background: rgb(254, 208, 37);
      background: linear-gradient(180deg, rgba(254, 208, 37, 1) 0%, rgba(240, 178, 17, 1) 100%);
      width: 5rem;
      height: 1.9rem;
      line-height: 20px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
      border-radius: 8px;
      padding: 0.4rem;
      border-radius: 7px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
      cursor: pointer;

      @media screen and (max-width: 510px) {
        width: 3rem;
        font-size: 14px;
      }
      @media screen and (max-width: 380px) {
        width: 2.5rem;
        font-size: 13px;
      }
      @media screen and (max-width: 320px) {
        width: 2rem;
        font-size: 11px;
        margin-left: -7px;
      }
      &.live {
        background-color: #77a405;
        color: #fff;
      }
      &__text-mobile {
        display: none;
        @media screen and (max-width: 510px) {
          display: inline-block;
        }
      }
      &__text-stats {
        display: inline-block;
        @media screen and (max-width: 510px) {
          display: none;
        }
      }
    }
    &__info-button-2 {
      box-sizing: border-box;
      font-family: 'Circular-Std-Medium';
      font-size: 10px;
      color: #3a3a3a;
      letter-spacing: -0.05px;
      text-align: center;
      margin-left: -12px;
      margin-top: 0.5rem;
      background: rgb(254, 208, 37);
      background: linear-gradient(180deg, rgba(254, 208, 37, 1) 0%, rgba(240, 178, 17, 1) 100%);
      width: 4.2rem;
      height: 1.9rem;
      line-height: 20px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
      border-radius: 8px;
      padding: 0.4rem;
      border-radius: 7px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
      cursor: pointer;
      @media screen and (max-width: 768px) {
        width: 3.7rem;
        font-size: 9px;
      }
      &.live {
        background-color: #77a405;
        color: #fff;
      }
      &__text-mobile {
        display: none;
      }
      &__text-stats {
        display: inline-block;
      }
    }
    &__info-button-3 {
      box-sizing: border-box;
      font-family: 'Circular-Std-Medium';
      font-size: 12px;
      color: #3a3a3a;
      letter-spacing: -0.05px;
      text-align: center;
      margin-left: -8px;
      margin-top: 0.5rem;
      background: rgb(254, 208, 37);
      background: linear-gradient(180deg, rgba(254, 208, 37, 1) 0%, rgba(240, 178, 17, 1) 100%);
      width: 3rem;
      height: 1.9rem;
      line-height: 20px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
      border-radius: 8px;
      padding: 0.4rem;
      border-radius: 7px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
      cursor: pointer;
      &.live {
        background-color: #77a405;
        color: #fff;
      }
      &__text-mobile {
        display: none;
      }
      &__text-stats {
        display: inline-block;
      }
    }
    &__info-button-4 {
      box-sizing: border-box;
      font-family: 'Circular-Std-Medium';
      font-size: 12px;
      color: #3a3a3a;
      letter-spacing: -0.05px;
      text-align: center;
      margin-left: -8px;
      margin-top: 0.5rem;
      background: rgb(254, 208, 37);
      background: linear-gradient(180deg, rgba(254, 208, 37, 1) 0%, rgba(240, 178, 17, 1) 100%);
      width: 3rem;
      height: 1.9rem;
      line-height: 20px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
      border-radius: 8px;
      padding: 0.4rem;
      border-radius: 7px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
      cursor: pointer;
      &.live {
        background-color: #77a405;
        color: #fff;
      }
      &__text-mobile {
        display: none;
      }
      &__text-stats {
        display: inline-block;
      }
    }
  }
}
</style>
